<script>
import { fetchBlog } from '@/api'
import { registerMatomoEvent } from '@/helpers/matomo'
import Blogs from '@/templates/static/Blogs'
import { EmbeddedVideo } from 'epmc-patterns/components/v2'
import SupportersSection from '@/templates/static/SupportersSection'

export default {
  metaInfo() {
    return {
      title: 'Home - Europe PMC',
    }
  },
  components: { Blogs, EmbeddedVideo, SupportersSection },
  data() {
    return {
      blogs: [],
      showSupporters: false,
      firstVideoImgUrl: require('@/assets/what-is-europe-pmc.jpeg'),
      secondVideoImgUrl: require('@/assets/how-researchers-are-using-europe-pmc.jpg'),
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      fetchBlog({}).then((response) => {
        this.blogs = response
      })
      this.showSupporters = true
      window.removeEventListener('scroll', this.handleScroll)
    },
    registerMatomoEvent,
  },
}
</script>
<template>
  <div id="home-page">
    <div class="grid-row">
      <div class="col-16">
        <div class="home-section">
          <p class="h2">Learn more about Europe PMC</p>
          <div id="home-videos">
            <embedded-video
              vid="2jyY9_hnpB8"
              duration="1:52"
              title="What is Europe PMC?"
              :image-url="firstVideoImgUrl"
            ></embedded-video>
            <embedded-video
              vid="L9Xzn0Y0rg4"
              duration="5:06"
              title="How researchers are using Europe PMC"
              :image-url="secondVideoImgUrl"
            ></embedded-video>
          </div>
        </div>

        <div v-if="blogs.length" class="home-section">
          <hr class="thick" />
          <p class="h2">News from Europe PMC</p>
          <blogs id="home-blogs" :blogs="blogs" />
        </div>

        <supporters-section v-if="showSupporters" class="home-section" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#home-page {
  text-align: center;
  .home-section {
    margin-bottom: $base-unit * 8;
  }
  #home-videos {
    display: flex;
    justify-content: center;
    gap: $base-unit * 8;
    text-align: left;
    @media screen and (max-width: $breakpoint-extra-small) {
      flex-wrap: wrap;
      .video {
        margin: 0;
      }
    }
    .video {
      margin: ($base-unit * 8) 0;
    }
  }
  #home-blogs {
    text-align: left;
  }
}
</style>
